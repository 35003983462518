import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import CourseItem from './CourseItem';
import { getAllCourseService } from '../../services/courseService';
import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material';
const Container = styled('div')({
    backgroundColor: '#f0f0f0',
    padding: '30px 60px 60px 60px',
    '@media screen and (max-width:500px)': {
        padding: '30px 20px 60px 20px',
    },
});

export default function Course(props = {}) {
    const [dataCourse, setdataCourse] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    useEffect(() => {
        loadDataCourse();
    }, []);
    const loadDataCourse = async () => {
        setisLoading(true);
        let res = await getAllCourseService({
            limit: '',
            offset: '',
            keyword: '',
        });
        if (res && res.errCode === 0) {
            res.data = res.data.map((item) => {
                return {
                    ...item,
                    levelName: item.levelData.value,
                };
            });
            setdataCourse(res.data);
            setisLoading(false);
        }
    };
    return (
        <Container>
            {!isLoading ? (
                <Grid container spacing={2} mt={'20px'}>
                    {dataCourse &&
                        dataCourse.length > 0 &&
                        dataCourse.map((item, index) => {
                            return (
                                <Grid item sm={12} lg={3}>
                                    <CourseItem key={index} course={item} />
                                </Grid>
                            );
                        })}
                </Grid>
            ) : (
                <Box>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                </Box>
            )}
        </Container>
    );
}
