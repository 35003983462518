import DashBoard from '../Dashboard/index';
function DashBoardContainer(props) {
   
    return (
        <>
            <DashBoard  />
           
        </>
    );
}
export default DashBoardContainer;
