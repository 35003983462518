import Footer from '../Components/Layout/Footer';
import Navbar from '../Components/Layout/Navbar';
import React from 'react';
import { Box } from '@mui/material';
export const withLayoutHomePage = (Component) => (props) => {
    return (
        <React.Fragment>
            <Navbar />
            <Box sx={{ paddingTop: '86px' }}>
                <Component />
            </Box>

            <Footer />
        </React.Fragment>
    );
};
