import axios from '../axios/axios';

const getAllOrderService = ({ date }) => {
    return axios.get(`/api/v1/order?date=${date}`);
};

const getOrderDetailService = (orderId) => {
    return axios.get(`/api/v1/order/${orderId}`);
};

const createOrderService = (data) => {
    return axios.post(`/api/v1/order`, data);
};

const deleteOrderService = (data) => {
    return axios.delete(`/api/v1/order`, {
        data: {
            ...data,
        },
    });
};

export { getAllOrderService, getOrderDetailService, createOrderService, deleteOrderService };
