import { useEffect, useState } from 'react';
import { Box, useTheme, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../Components/common/HeaderList';
import { Link } from 'react-router-dom';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { USER_REFERENCE } from '../../utils/constant';
import { getAllDonateService } from '../../services/donateService';
import { formatNumber } from '../../utils/CommonUtils';

export default function Donate(props = {}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataDonater, setdataDonater] = useState([]);

    const [arrDonaterId, setarrDonaterId] = useState([]);
    const [ColumnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        loadDataDonate();
    }, []);
    const loadDataDonate = async () => {
        setisLoading(true);
        let res = await getAllDonateService();
        if (res && res.errCode === 0) {
            setdataDonater(res.data);
            setisLoading(false);
        }
    };

    const handleOnchangeColumns = (newModel) => {
        let userReference = JSON.parse(localStorage.getItem(USER_REFERENCE.USER_REFERENCE));
        localStorage.setItem(USER_REFERENCE.USER_REFERENCE, JSON.stringify({ ...userReference, tableDonater: newModel }));
        setColumnVisibilityModel(newModel);
    };
    const columns = [
        { field: 'id', headerName: 'ID' },
        {
            field: 'fullname',
            headerName: 'Họ và tên',
            flex: 1,
            cellClassName: 'name-column--cell',
        },
        {
            field: 'phonenumber',
            headerName: 'Số điện thoại',
            flex: 1,
        },

        {
            field: 'amount',
            headerName: 'Số tiền',
            flex: 1,
            renderCell: ({ row: { amount } }) => {
                return (
                    <Typography color={colors.redAccent[500]} sx={{ ml: '5px' }}>
                        {formatNumber(amount)}
                    </Typography>
                );
            },
        },
    ];

    return (
        <Box m="20px">
            <Header title="ỦNG HỘ" subtitle="Quản lý người ủng hộ" />
            <Box display="flex" justifyContent="end">
                <Link to={'/admin/donate/addDonate'} style={{ textDecoration: 'none' }}>
                    <Button type="submit" color="secondary" variant="contained">
                        <PersonAddOutlinedIcon sx={{ marginRight: 1 }} />
                        Thêm ủng hộ
                    </Button>
                </Link>
                <Button type="submit" color="redAccent" variant="contained" sx={{ marginLeft: 1 }}>
                    <DeleteSweepIcon sx={{ marginRight: 1 }} />
                    Xóa
                </Button>
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                        color: colors.greenAccent[300],
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        backgroundColor: colors.primary[400],
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: colors.blueAccent[700],
                    },
                    '& .MuiCheckbox-root': {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    '& .MuiCircularProgress-root': {
                        color: colors.greenAccent[400],
                    },
                }}
            >
                <DataGrid
                    onSelectionModelChange={(arrDonaterId) => {
                        setarrDonaterId(arrDonaterId);
                    }}
                    selectionModel={arrDonaterId}
                    checkboxSelection
                    loading={isLoading}
                    rows={dataDonater}
                    columns={columns}
                    columnVisibilityModel={ColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => handleOnchangeColumns(newModel)}
                />
            </Box>
        </Box>
    );
}
