import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import education from '../../media/education.png';
import free from '../../media/free.png';
import teacher from '../../media/teacher.png';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CustomButton from '../common/CustomButton';
import { Link } from 'react-router-dom';

const Guide = () => {
    const CustomBox = styled(Box)(({ theme }) => ({
        width: '30%',
        [theme.breakpoints.down('md')]: {
            width: '85%',
        },
    }));

    const GuidesBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-around',
        width: '70%',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0',
            flexDirection: 'column',
        },
    }));

    const GuideBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 2, 0),
        },
    }));

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(245, 250, 254)',
                padding: '40px',
            }}
        >
            <div
                style={{
                    width: '5%',
                    height: '5px',
                    backgroundColor: '#000339',
                    margin: '0 auto',
                }}
            ></div>

            <Typography variant="h3" sx={{ fontSize: '35px', fontWeight: 'bold', color: '#000339', my: 3 }}>
                Những giá trị cốt lõi
            </Typography>

            <CustomBox>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#5A6473',
                        textAlign: 'center',
                    }}
                >
                    Những điều mà kênh Lập trình FullStack muốn đem lại cho mọi người
                </Typography>
            </CustomBox>

            <GuidesBox>
                <GuideBox>
                    <img style={{ width: '48px' }} src={education} alt="education" />
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: '500',
                            fontSize: '20px',
                            color: '#3B3c45',
                            my: 1,
                        }}
                    >
                        Best Education
                    </Typography>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0689FF' }}>
                            Học những kiến thức thực tế
                        </Typography>
                        <ArrowRightAltIcon style={{ color: '#0689FF' }} />
                    </Box>
                </GuideBox>

                <GuideBox>
                    <img style={{ width: '48px' }} src={teacher} alt="teacher" />
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: '500',
                            fontSize: '20px',
                            color: '#3B3c45',
                            my: 1,
                        }}
                    >
                        Best Teacher
                    </Typography>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0689FF' }}>
                            Giảng viên với phong cách trẻ trung genZ
                        </Typography>
                        <ArrowRightAltIcon style={{ color: '#0689FF' }} />
                    </Box>
                </GuideBox>

                <GuideBox>
                    <img style={{ width: '48px' }} src={free} alt="free" />
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: '500',
                            fontSize: '20px',
                            color: '#3B3c45',
                            my: 1,
                        }}
                    >
                        Free all
                    </Typography>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0689FF' }}>
                            Tất cả đều miễn phí
                        </Typography>
                        <ArrowRightAltIcon style={{ color: '#0689FF' }} />
                    </Box>
                </GuideBox>
            </GuidesBox>

            <Link to={'/course'}>
                <CustomButton backgroundColor="#0F1B4C" color="#fff" buttonText="Xem khóa học" guideBtn={true} />
            </Link>
        </Box>
    );
};

export default Guide;
