import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import 'react-markdown-editor-lite/lib/index.css';
import { withFormatDate } from './hoc/withFormatDate';
import { withToast } from './hoc/withToast';
import { compose } from 'redux';
import MyRoutes from './Components/Routes/Routes';

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
        <CssBaseline />
          <MyRoutes />
    </ThemeProvider>
</ColorModeContext.Provider>
  );
}

export default compose(withToast, withFormatDate)(App);
