import axios from '../axios/axios';
const addCourseService = (data) => {
    return axios.post(`/api/add-course`,data);
};
const getAllCourseService = (data) =>{
    return axios.get(`api/get-all-course?limit=${data.limit}&offset=${data.offset}&keyword=${data.keyword}`);
}
const updateCourseService = (data) => {
    return axios.put(`/api/update-course`,data);
};
const GetDetailCourseByIdService = (id) => {
    return axios.get(`/api/get-detail-course-by-id?id=${id}`);
};
export { addCourseService,getAllCourseService,updateCourseService,GetDetailCourseByIdService };
