import { Route, Routes } from 'react-router-dom';
import HomePage from '../../Components/Home/HomePage';
import PrivateRoute from '../Routes/PrivateRoute';
import AdminRouter from '../Routes/AdminRouter';
import LoginPage from '../../pages/Login/LoginPage';
import CoursePage from '../../pages/course/CoursePage';
import DonateClientPage from '../../pages/donate/DonateClientPage';
import ConnectPage from '../../pages/connect/ConnectPage';
export default function MyRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/course" element={<CoursePage />} />
            <Route path="/donate" element={<DonateClientPage />} />
            <Route path="/connect" element={<ConnectPage />} />
            <Route path="/auth/login" element={<LoginPage />} />
            <Route
                path="/admin/*"
                element={
                    <PrivateRoute>
                        <AdminRouter />
                    </PrivateRoute>
                    // <AdminRouter />
                }
            />
        </Routes>
    );
}
