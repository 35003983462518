import Dashboard from '../../pages/dashboard/index';
import User from '../../pages/user/index';
import AddUser from '../../pages/user/AddUser';
import DetailUser from '../../pages/user/DetailUser';
import { Route, Routes } from 'react-router-dom';
import DonatePage from '../../pages/donate/DonatePage';
import AddCoursePage from '../../pages/admin/course/AddCoursePage';
import AddDonatePage from '../../pages/donate/AddDonatePage';
import CoursePage from '../../pages/admin/course/CoursePage';
import OrderPage from '../../pages/order/OrderPage';
import AddOrderPage from '../../pages/order/AddOrderPage';

function AdminRouter() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/user" element={<User />} />
                <Route path="/user/addUser" element={<AddUser />} />
                <Route path="/user/detail/:id" element={<DetailUser />} />
                <Route path="/donate" element={<DonatePage />} />
                <Route path="/donate/addDonate" element={<AddDonatePage />} />
                <Route path="/course/addCourse" element={<AddCoursePage />} />
                <Route path="/course/addCourse/:id" element={<AddCoursePage />} />
                <Route path="/course" element={<CoursePage />} />
                <Route path="/order" element={<OrderPage />} />
                <Route path="/order/create-order" element={<AddOrderPage />} />
            </Routes>
        </>
    );
}
export default AdminRouter;
