import axios from '../axios/axios';

const getAllDonaterService = () => {
    return axios.get(`/api/get-all-donater`);
};
const addDonaterService = (data) => {
    return axios.post(`/api/add-donater`,data);
};
const addDonateService = (data) => {
    return axios.post(`/api/add-donate`,data);
};
const getAllDonateService = () => {
    return axios.get(`/api/get-all-donate`);
};
export {
    getAllDonaterService,addDonaterService,addDonateService,getAllDonateService
}