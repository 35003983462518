import Sidebar from '../Components/Layout/Sidebar';
import Topbar from '../Components/Layout/Topbar';
export const withLayoutAdmin = (Component) => (props) => {
    return (
        <>
            <div className='app'>
                <Sidebar />
                <main className="content">
                    <Topbar />
                    <Component />
                </main>
            </div>
            
        </>
    );
};
