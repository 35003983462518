import { configureStore } from '@reduxjs/toolkit';
import LanguageReducer from '../redux/Reducer/LanguageReducer';
import UserReducer from './Reducer/UserReducer';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
const persistCommonConfig = {
    storage,
    stateReconciler: autoMergeLevel2,
};
const userPersistConfig = {
    ...persistCommonConfig,
    key: 'dataUser',
    whitelist: ['dataUser'],
};
const persistedReducer = persistReducer(userPersistConfig, UserReducer);

export const store = configureStore({
    reducer: { UserReducer: persistedReducer, LanguageReducer },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
});


export const persistor = persistStore(store);
