import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function AddAutoComplete(props = {}) {
  const {listDonater,handleSave,handleOnChange} = props
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    fullname: '',
    phonenumber: '',
  });
  const handleClose = () => {
    setDialogValue({
      fullname: '',
      phonenumber: '',
    });
    toggleOpen(false);
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      fullname: dialogValue.fullname,
      phonenumber: dialogValue.fullname,
    });
    handleSave({
      fullname: dialogValue.fullname,
      phonenumber: dialogValue.phonenumber,
    })
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                fullname: newValue,
                phonenumber: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              fullname: newValue.inputValue,
              phonenumber: '',
            });
          } else {
            handleOnChange(newValue)
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              fullname: `Thêm "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={listDonater}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.fullname ;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.fullname}</li>}
        sx={{ gridColumn: 'span 2'  }}
        freeSolo
        renderInput={(params) => <TextField color="secondary" fullWidth variant="filled" {...params} label="Select Donater" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Thêm người ủng hộ mới</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Bạn có bỏ lỡ một người ủng hộ nào trong danh sách không ? Vui lòng thêm
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.fullname}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  fullname: event.target.value,
                })
              }
              label="Họ và tên"
              type="text"
              color="secondary"
              fullWidth 
              variant="filled"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.phonenumber}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  phonenumber: event.target.value,
                })
              }
              label="Số điện thoại"
              type="text"
              color="secondary"
              fullWidth 
              variant="filled"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Hủy</Button>
            <Button type="submit">Thêm</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
