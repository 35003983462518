import { Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import Header from '../../Components/common/HeaderList';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { FORMAT, USER_REFERENCE } from '../../utils/constant';
import { deleteOrderService, getAllOrderService } from '../../services/orderService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { formatNumber } from '../../utils/CommonUtils';
import SubmitLoading from '../../Components/common/SubmitLoading';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export default function ManageOrder() {
    const [filterDate, setFilterDate] = useState(new Date());
    const [total, setTotal] = useState(0);
    const [LoadingSubmit, setLoadingSubmit] = useState(false);
    const [arrOrderId, setarrOrderId] = useState([]);
    const [dataOrder, setDataOrder] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [ColumnVisibilityModel, setColumnVisibilityModel] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columns = [
        { field: 'id', headerName: 'Mã đơn hàng' },
        {
            field: 'customerName',
            headerName: 'Khách hàng',
            flex: 1,
            cellClassName: 'name-column--cell',
        },
        {
            field: 'date',
            headerName: 'Ngày mua hàng',
            flex: 1,
            valueGetter: ({ value }) => {
                return moment(value).format('DD/MM/YYYY');
            },
        },
        {
            field: 'total',
            headerName: 'Tổng giá trị',
            flex: 1,
            valueGetter: ({ value }) => {
                return formatNumber(value);
            },
        },
        {
            field: 'note',
            headerName: 'Ghi chú',
            flex: 1,
        },

        {
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row: { id } }) => {
                return (
                    <Link to={`/admin/order/detail/${id}`} style={{ textDecoration: 'none', color: colors.grey[100] }}>
                        <Button type="submit" color="neutral" variant="contained">
                            <PageviewOutlinedIcon sx={{ marginRight: 1 }} />
                            View
                        </Button>
                    </Link>
                );
            },
        },
    ];

    const handleOnchangeColumns = (newModel) => {
        let userReference = JSON.parse(localStorage.getItem(USER_REFERENCE.USER_REFERENCE));
        localStorage.setItem(USER_REFERENCE.USER_REFERENCE, JSON.stringify({ ...userReference, tableOrder: newModel }));
        setColumnVisibilityModel(newModel);
    };

    const fetchOrder = useCallback(async () => {
        try {
            setisLoading(true);
            const res = await getAllOrderService({ date: moment(filterDate).format('MM/YYYY') });
            if (res.errCode === 0 && res.data) {
                setDataOrder(res.data);
                setTotal(
                    res.data.reduce((preVal, curVal) => {
                        return preVal + +curVal.total;
                    }, 0),
                );
            }
        } catch (error) {
            toast.error(error.response.data.errMessage);
        } finally {
            setisLoading(false);
        }
    }, [filterDate]);

    const fetchUserReference = () => {
        let userReference = JSON.parse(localStorage.getItem(USER_REFERENCE.USER_REFERENCE));
        if (userReference) {
            setColumnVisibilityModel(userReference.tableOrder);
        }
    };

    const handleDeleteOrder = async () => {
        try {
            setLoadingSubmit(true);
            const res = await deleteOrderService({ orderIds: arrOrderId });
            if (res && res.errCode === 0) {
                toast.success('Xóa đơn hàng thành công');
                fetchOrder();
            }
        } catch (error) {
        } finally {
            setLoadingSubmit(false);
        }
    };

    useEffect(() => {
        fetchOrder();
        fetchUserReference();
    }, [fetchOrder]);

    return (
        <Box m="20px">
            <Header title="ĐƠN HÀNG" subtitle="Quản lý đơn hàng" />
            <Box display="flex" justifyContent="space-between">
                <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
                    <DesktopDatePicker
                        label="Lọc theo tháng"
                        inputFormat={FORMAT.MONTH_YEAR}
                        value={filterDate}
                        name="date"
                        onChange={(value) => {
                            setFilterDate(value['$d']);
                        }}
                        renderInput={(params) => <TextField color="secondary" variant="filled" {...params} />}
                        view={'month'}
                    />
                    <Typography variant="h5" color={colors.redAccent[500]} fontFamily={600}>
                        Tổng doanh thu: {formatNumber(total)}
                    </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'}>
                    <Link to={'/admin/order/create-order'} style={{ textDecoration: 'none' }}>
                        <Button type="submit" color="secondary" variant="contained">
                            <AddCircleOutlineIcon sx={{ marginRight: 1 }} />
                            Thêm
                        </Button>
                    </Link>
                    <Button onClick={() => handleDeleteOrder()} type="submit" color="redAccent" variant="contained" sx={{ marginLeft: 1 }}>
                        <DeleteSweepIcon sx={{ marginRight: 1 }} />
                        Xóa
                    </Button>
                </Stack>
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                        color: colors.greenAccent[300],
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        backgroundColor: colors.primary[400],
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: colors.blueAccent[700],
                    },
                    '& .MuiCheckbox-root': {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    '& .MuiCircularProgress-root': {
                        color: colors.greenAccent[400],
                    },
                }}
            >
                <DataGrid
                    onSelectionModelChange={(arrOrderId) => {
                        setarrOrderId(arrOrderId);
                    }}
                    selectionModel={arrOrderId}
                    checkboxSelection
                    loading={isLoading}
                    rows={dataOrder}
                    columns={columns}
                    columnVisibilityModel={ColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => handleOnchangeColumns(newModel)}
                />
            </Box>
            <SubmitLoading loading={LoadingSubmit} />
        </Box>
    );
}
