import { useCallback, useEffect, useState } from 'react';
import { getAllCourseService } from '../services/courseService';

const useFetchAllCourse = (limit, offset, keyword) => {
    const [dataCourse, setdataCourse] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const loadDataCourse = useCallback(async () => {
        setisLoading(true);
        let res = await getAllCourseService({
            limit,
            offset,
            keyword,
        });
        if (res && res.errCode === 0) {
            res.data = res.data.map((item) => {
                return {
                    ...item,
                    levelName: item.levelData.value,
                };
            });
            setdataCourse(res.data);
            setisLoading(false);
        }
    }, [keyword, limit, offset]);

    useEffect(() => {
        loadDataCourse();
    }, [loadDataCourse]);

    return { isLoading, dataCourse, setdataCourse };
};
export { useFetchAllCourse };
