import { Box } from '@mui/material';
import Header from '../../Components/common/HeaderList';
import { FormattedMessage } from 'react-intl';
function Dashboard() {
    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header
                    title={<FormattedMessage id="dashboardPage.dashboard" />}
                    subtitle={<FormattedMessage id="dashboardPage.subtitle" />}
                />
            </Box>
            {/* GRID & CHARTS */}
          
        </Box>
    );
}

export default Dashboard;
