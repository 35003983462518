import React, { useState, useEffect } from 'react';
import SubmitLoading from '../../Components/common/SubmitLoading';
import Header from '../../Components/common/HeaderList';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '@mui/material/Autocomplete';
import { TYPE_ALLCODE } from '../../utils/constant';
import { useFetchAllcode } from '../../hooks/fetchAllcode';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import Typography from '@mui/material/Typography';
import { tokens } from '../../theme';
import { useTheme, Button } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

import Lightbox from 'react-image-lightbox';
import { addCourseService, GetDetailCourseByIdService, updateCourseService } from '../../services/courseService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getBase64 } from '../../utils/CommonUtils';

export default function AddCourse(props = {}) {
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const mdParser = new MarkdownIt();
    var { data: dataLevel } = useFetchAllcode(TYPE_ALLCODE.COURSE_LEVEL);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { id } = useParams();
    const [LoadingSubmit, setLoadingSubmit] = useState(false);
    const [inputValues, setInputValues] = useState({
        title: '',
        shortdescription: '',
        contentMarkdown: '',
        contentHTML: '',
        thumbnail: '',
        linkPlayList: '',
        levelId: '',
        levelOptions: '',
        imageReview: '',
        isOpen: false,
        isAddAction: true,
        price: '',
    });
    useEffect(() => {
        if (id) {
            fetchDataCourse(id);
        }
    }, [id]);
    let fetchDataCourse = async (id) => {
        let res = await GetDetailCourseByIdService(id);
        if (res && res.errCode === 0) {
            let { data } = res;
            setInputValues({
                title: data.title,
                shortdescription: data.shortdescription,
                contentMarkdown: data.contentMarkdown,
                contentHTML: data.contentHTML,
                thumbnail: data.thumbnail,
                linkPlayList: data.linkPlayList,
                levelId: data.levelId,
                levelOptions: data.levelData.value,
                isAddAction: false,
                price: data.price,
            });
        }
    };
    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    let handleEditorChange = ({ html, text }) => {
        setInputValues({
            ...inputValues,
            contentMarkdown: text,
            contentHTML: html,
        });
    };
    let handleOnChangeImage = async (event) => {
        let data = event.target.files;
        let file = data[0];
        if (file.size > 31312281) {
            alert('Dung lượng file bé hơn 30mb');
        } else {
            let base64 = await getBase64(file);
            let objectUrl = URL.createObjectURL(file);
            setInputValues({ ...inputValues, thumbnail: base64, imageReview: objectUrl });
        }
    };
    let handleSaveCourse = async () => {
        try {
            setLoadingSubmit(true);
            let objectData = {
                title: inputValues.title,
                shortdescription: inputValues.shortdescription,
                contentMarkdown: inputValues.contentMarkdown,
                contentHTML: inputValues.contentHTML,
                thumbnail: inputValues.thumbnail,
                linkPlayList: inputValues.linkPlayList,
                levelId: inputValues.levelId,
                price: inputValues.price,
                id: id,
            };
            if (inputValues.isAddAction) {
                let res = await addCourseService(objectData);
                if (res && res.errCode === 0) {
                    toast.success('Save Course Successfully !');
                    setLoadingSubmit(false);
                    setInputValues({
                        ...inputValues,
                        title: '',
                        shortdescription: '',
                        contentMarkdown: '',
                        contentHTML: '',
                        thumbnail: '',
                        linkPlayList: '',
                        levelId: '',
                        levelOptions: '',
                        imageReview: '',
                        isOpen: false,
                        price: '',
                    });
                } else {
                    toast.error(res.errMessage);
                }
            } else {
                let res = await updateCourseService(objectData);
                if (res && res.errCode === 0) {
                    toast.success('Update Course successfully !');
                } else {
                    toast.error(res.errMessage);
                }
            }
        } catch (error) {
        } finally {
            setLoadingSubmit(false);
        }
    };
    let openPreviewImage = () => {
        if (!inputValues.imageReview) return;

        setInputValues({ ...inputValues, isOpen: true });
    };
    return (
        <Box m="20px">
            <Header
                title={inputValues.isAddAction ? 'CREATE COURSE' : 'UPDATE COURSE'}
                subtitle={inputValues.isAddAction ? 'Create a New Course' : 'Update a course'}
            />

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                sx={{
                    '& > div': { gridColumn: isNonMobile ? undefined : 'span 6' },
                    paddingBottom: '50px',
                }}
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Title"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.title}
                    name="title"
                    color="secondary"
                    sx={{ gridColumn: 'span 3' }}
                />
                <Autocomplete
                    disablePortal
                    onChange={(event, newValue) => {
                        setInputValues({ ...inputValues, levelId: newValue.value, levelOptions: newValue });
                    }}
                    value={inputValues.levelOptions}
                    options={dataLevel}
                    sx={{ gridColumn: 'span 3' }}
                    renderInput={(params) => <TextField color="secondary" fullWidth variant="filled" {...params} label="Level" />}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Price"
                    onChange={(e) => setInputValues({ ...inputValues, price: +e.target.value })}
                    value={inputValues.price}
                    name="price"
                    color="secondary"
                    sx={{ gridColumn: 'span 3' }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Link course"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.linkPlayList}
                    name="linkPlayList"
                    color="secondary"
                    sx={{ gridColumn: 'span 3' }}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Shortdescription"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.shortdescription}
                    name="shortdescription"
                    color="secondary"
                    multiline
                    rows={4}
                    sx={{ gridColumn: 'span 6' }}
                />
                <Box sx={{ gridColumn: 'span 6' }}>
                    <Typography variant="h5" fontWeight={500} mb={1} color={colors.grey[100]}>
                        Description project
                    </Typography>
                    <MdEditor
                        style={{ height: '50vh' }}
                        renderHTML={(text) => mdParser.render(text)}
                        onChange={handleEditorChange}
                        value={inputValues.contentMarkdown}
                    />
                </Box>
                <Box sx={{ gridColumn: 'span 6' }} display="flex" alignItems="flex-start" flexDirection="column" gap="10px" mt="10px">
                    <span id="title">Thumbnail</span>
                    <img
                        data-testid="imgReview"
                        onClick={() => openPreviewImage()}
                        src={inputValues.thumbnail}
                        style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer', borderRadius: 5 }}
                        alt="avatar"
                    />
                    <Box display={'flex'} gap="10px" alignItems={'center'}>
                        <Button variant="contained" component="label" color="secondary">
                            <PhotoCamera sx={{ marginRight: 1 }} />
                            Upload
                            <input hidden accept="image/*" onChange={(event) => handleOnChangeImage(event)} type="file" />
                        </Button>
                        <Button onClick={() => handleSaveCourse()} type="submit" color="redAccent" variant="contained">
                            {inputValues.isAddAction ? 'Create New Course' : 'Update Course'}
                        </Button>
                    </Box>
                </Box>
            </Box>
            {inputValues.isOpen === true && (
                <Lightbox
                    data-testid="Lightbox"
                    mainSrc={inputValues.imageReview}
                    onCloseRequest={() => setInputValues({ ...inputValues, isOpen: false })}
                />
            )}
            <SubmitLoading loading={LoadingSubmit} />
        </Box>
    );
}
