import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { formatNumber } from '../../utils/CommonUtils';

const ContainerCourse = styled('div')({
    backgroundColor: '#fff',
    padding: '8px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '@media screen and (max-width:500px)': {
        width: '100%',
    },
});
const CourseTopWrap = styled('div')({
    position: 'relative',
});
const Title = styled('span')({
    fontSize: '20px',
    '@media screen and (max-width:500px)': {
        fontSize: '16px',
    },
});
const CourseBottomWrap = styled('div')({
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
});
const Price = styled('div')({
    backgroundColor: '#cb1c22',
    borderRadius: '15px',
    lineHeight: '28px',
    padding: '4px 16px',
    color: '#fff',
    '@media screen and (max-width:500px)': {
        padding: '4px 10px',
        lineHeight: '14px',
    },
});
const PriceTypography = styled('Typography')({
    ml: '5px',
    fontSize: '18px',
    fontWeight: 700,
    '@media screen and (max-width:500px)': {
        fontSize: '14px',
    },
});
const Thumbnail = styled('img')({
    width: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    borderRadius: '5px',
    height: '250px',
    '@media screen and (max-width:500px)': {
        width: '100%',
        height: '200px',
    },
});
export default function CourseItem({ course } = {}) {
    return (
        <ContainerCourse>
            <CourseTopWrap>
                <Thumbnail src={course.thumbnail}></Thumbnail>
                <Box class="play-btn"></Box>
            </CourseTopWrap>
            <CourseBottomWrap>
                <Box style={{ cursor: 'pointer' }}>
                    <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }} href={course.linkPlayList}>
                        <Title>{course.title}</Title>
                    </a>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '20px' }}>
                    <a target="_blank" rel="noreferrer" href={course.linkPlayList}>
                        <span
                            style={{
                                color: '#3cf',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                cursor: 'pointer',
                                fontSize: '18px',
                            }}
                        >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 16 16"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                ></path>
                            </svg>
                            Xem chi tiết
                        </span>
                    </a>
                    <Price>
                        <PriceTypography>{formatNumber(course.price)}</PriceTypography>
                    </Price>
                </Box>
            </CourseBottomWrap>
        </ContainerCourse>
    );
}
