import { Box, Container, styled, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllDonateService } from '../../services/donateService';
import LogoPerson from '../../media/logoPerson.png';
import Skeleton from '@mui/material/Skeleton';
import { formatNumber } from '../../utils/CommonUtils';

const Donate = () => {
    const [dataDonate, setDataDonate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        loadDataDonate();
    }, []);
    const loadDataDonate = async () => {
        setIsLoading(true);
        let res = await getAllDonateService();
        if (res && res.errCode === 0) {
            setDataDonate(res.data.sort((a, b) => b.amount - a.amount));
            setIsLoading(false);
        }
    };
    const DonateTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    }));

    return (
        <Box sx={{ backgroundColor: '#F5FAFE', py: 10 }}>
            <Container>
                <DonateTextBox>
                    <Typography sx={{ color: '#000339', fontSize: '35px', fontWeight: 'bold' }}>Những người đóng góp</Typography>
                    <Typography sx={{ color: '#5A6473', fontSize: '16px', mt: 1 }}>
                        Sự đóng góp của bạn là nguồn động lực to lớn của chúng tôi
                    </Typography>
                </DonateTextBox>
                <Box className="donate-wrapper">
                    {!isLoading ? (
                        <React.Fragment>
                            {dataDonate && dataDonate.length > 0 && (
                                <Box className="donate-wrapper-top">
                                    <img alt="photoWeb" src={LogoPerson}></img>
                                    <img alt="photoWeb" src={`${window.location.origin}/top1.png`} className="background-top1"></img>
                                    <p className="name-donate">
                                        {dataDonate[0].fullname + ' - xxxxxxx' + dataDonate[0].phonenumber.slice(7)}
                                    </p>
                                    <p className="money-donate">{formatNumber(dataDonate[0].amount)}</p>
                                </Box>
                            )}
                            <Box className="donate-wrapper-bottom">
                                {dataDonate &&
                                    dataDonate.length > 0 &&
                                    dataDonate.map((item, index) => {
                                        if (index !== 0) {
                                            return (
                                                <Box className="donate-wrapper-bottom-item">
                                                    <Box className="donate-wrapper-bottom-item-left">
                                                        <span className="order-donate-item">#{index + 1}</span>
                                                        <Box className="box-avatar">
                                                            <img className="avatar-donate-item" alt="photoWeb" src={LogoPerson}></img>
                                                            <img
                                                                className="rank-donate-item"
                                                                alt="photoWeb"
                                                                src={`${window.location.origin}/top${index + 1}.png`}
                                                            ></img>
                                                        </Box>
                                                        <span className="name-donate-item">
                                                            {item.fullname + ' - xxxxxxx' + item.phonenumber.slice(7)}
                                                        </span>
                                                    </Box>
                                                    <Box className="donate-wrapper-bottom-item-right">{formatNumber(item.amount)}</Box>
                                                </Box>
                                            );
                                        } else {
                                            return <div></div>;
                                        }
                                    })}
                            </Box>
                        </React.Fragment>
                    ) : (
                        <Box>
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                        </Box>
                    )}

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: '#f0564a', fontWeight: 600, marginTop: 20 }} variant="contained">
                            Load more
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Donate;
