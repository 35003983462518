import { Box, Container, Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAllCourseService } from '../../services/courseService';
import CourseItem from '../../features/Course/CourseItem';

export default function HotCourse() {
    const [dataCourse, setdataCourse] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    useEffect(() => {
        loadDataCourse();
    }, []);
    const loadDataCourse = async () => {
        setisLoading(true);
        let res = await getAllCourseService({
            limit: '',
            offset: '',
            keyword: '',
        });
        if (res && res.errCode === 0) {
            res.data = res.data.map((item) => {
                return {
                    ...item,
                    levelName: item.levelData.value,
                };
            });
            setdataCourse(res.data);
            setisLoading(false);
        }
    };
    return (
        <Box sx={{ backgroundColor: '#FDF7E4', py: 10 }}>
            <Container>
                <Typography sx={{ color: '#000339', fontSize: '35px', fontWeight: 'bold' }}>Khóa học nổi bật</Typography>
                {!isLoading ? (
                    <Grid container spacing={2} mt={1}>
                        {dataCourse &&
                            dataCourse.length > 0 &&
                            dataCourse.map((item, index) => {
                                return (
                                    <Grid item sm={12} lg={4}>
                                        <CourseItem key={index} course={item} />
                                    </Grid>
                                );
                            })}
                    </Grid>
                ) : (
                    <Box>
                        <Skeleton />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation={false} />
                    </Box>
                )}
            </Container>
        </Box>
    );
}
