import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HomeIcon from '@mui/icons-material/Home';
import ContactsIcon from '@mui/icons-material/Contacts';
import logoImg from '../../media/logoWeb.png';
import { Container } from '@mui/system';
import CustomButton from '../common/CustomButton';
import { useSelector, useDispatch } from 'react-redux';
import UserInfoMenu from '../Layout/AppBar/UserInfoMenu';
import { HandleLogoutSuccess } from '../../redux/action/UserAction';
import { useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const Navbar = () => {
    let dataUser = useSelector((state) => state.UserReducer.dataUser);

    const [mobileMenu, setMobileMenu] = useState({
        left: false,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(HandleLogoutSuccess());
        window.location.href = process.env.REACT_APP_URL_LOGOUT;
    };
    const handleGotoAcount = () => {
        navigate(`/user/detail/${dataUser.id}`);
    };
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.type === 'Tab' || event.type === 'Shift')) {
            return;
        }

        setMobileMenu({ ...mobileMenu, [anchor]: open });
    };
    const navList = [
        { title: 'Trang chủ', link: '/', icon: <HomeIcon /> },
        { title: 'Khóa học', link: '/course', icon: <FeaturedPlayListIcon /> },
        { title: 'Tư vấn', link: '/advise', icon: <MiscellaneousServicesIcon /> },
        { title: 'Donate', link: '/donate', icon: <ListAltIcon /> },
        { title: 'Kết nối', link: '/connect', icon: <ContactsIcon /> },
    ];
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {navList.map((item, index) => (
                    <Link to={item.link}>
                        <ListItem key={index} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText sx={{ color: 'black' }} primary={item.title} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box>
    );

    const NavLink = styled(Typography)(({ theme }) => ({
        fontSize: '16px',
        color: '#4F5361',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            color: '#1473e6',
        },
    }));

    const NavbarLinksBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }));

    const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
        cursor: 'pointer',
        display: 'none',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    }));

    const NavbarContainer = styled(Container)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
        position: 'fixed',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e8ebed',
        zIndex: 1000,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    }));

    const NavbarLogo = styled('img')(({ theme }) => ({
        cursor: 'pointer',
        width: '69px',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    }));

    return (
        <NavbarContainer style={{ maxWidth: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '2.5rem',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CustomMenuIcon onClick={toggleDrawer('left', true)} />
                    <Drawer anchor="left" open={mobileMenu['left']} onClose={toggleDrawer('left', false)}>
                        {list('left')}
                    </Drawer>
                    <NavbarLogo src={logoImg} alt="logo" />
                </Box>

                <NavbarLinksBox>
                    <Link to={'/'}>
                        <NavLink variant="body2">Trang chủ</NavLink>
                    </Link>
                    <Link to={'/course'}>
                        <NavLink variant="body2">Khóa học</NavLink>
                    </Link>
                    <Link to={'/donate'}>
                        <NavLink variant="body2">Donate</NavLink>
                    </Link>
                    <Link to={'/connect'}>
                        <NavLink variant="body2">Kết nối</NavLink>
                    </Link>
                </NavbarLinksBox>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                }}
            >
                {dataUser && dataUser.roleId ? (
                    <React.Fragment>
                        <UserInfoMenu avatar={dataUser.image} handleLogout={handleLogout} handleGotoAcount={handleGotoAcount} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Link to={'/auth/login'}>
                            <NavLink variant="body2">Sign Up</NavLink>
                        </Link>
                        <Link to={'/auth/login'}>
                            <CustomButton backgroundColor="#0F1B4C" color="#fff" buttonText="Register" />
                        </Link>
                    </React.Fragment>
                )}
            </Box>
        </NavbarContainer>
    );
};

export default Navbar;
