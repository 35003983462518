import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../Components/common/HeaderList';
import SubmitLoading from '../../Components/common/SubmitLoading';
import { getAllDonaterService, addDonaterService, addDonateService } from '../../services/donateService';
import AddAutoComplete from '../../Components/common/AddAutocomplete';
import { toast } from 'react-toastify';

export default function AddDonate(props = {}) {
    const isNonMobile = useMediaQuery('(min-width:600px)');
    const [LoadingSubmit, setLoadingSubmit] = useState(false);
    const [dataDonater, setDataDonater] = useState([]);
    const [amount, setAmount] = useState('');
    const [donaterId, setDonaterId] = useState('');
    useEffect(() => {
        loadDataDonater();
    }, []);
    const loadDataDonater = async () => {
        setLoadingSubmit(true);
        let res = await getAllDonaterService();
        if (res && res.errCode === 0) {
            let array = res.data.map((item) => {
                return {
                    ...item,
                    fullname: item.fullname + ' - ' + item.phonenumber,
                };
            });
            setDataDonater(array);
            setLoadingSubmit(false);
        }
    };
    const handleSave = async (data) => {
        let res = await addDonaterService(data);
        if (res && res.errCode === 0) {
            toast.success('Thêm người ủng hộ thành công');
            loadDataDonater();
        } else {
            toast.error(res.errMessage);
        }
    };
    const handleOnChange = (data) => {
        setDonaterId(data.id);
    };
    const handleDonate = async () => {
        if (!donaterId) {
            toast.error('Hãy chọn người ủng hộ');
        } else {
            let res = await addDonateService({
                donaterId: donaterId,
                amount: +amount.replace('đ', '').replace(',', ''),
            });
            if (res && res.errCode === 0) {
                toast.success('Thêm donate thành công');
                setAmount('');
            }
        }
    };
    return (
        <Box m="20px">
            <Header title="ADD DONATER" subtitle="Create a New Donater Profile" />

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                    '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
            >
                <AddAutoComplete handleOnChange={handleOnChange} handleSave={handleSave} listDonater={dataDonater} />

                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    name="Amount"
                    color="secondary"
                    sx={{ gridColumn: 'span 2' }}
                />
                <Button onClick={() => handleDonate()} type="submit" sx={{ gridColumn: 'span 1' }} color="secondary" variant="contained">
                    Donate
                </Button>
            </Box>
            <SubmitLoading loading={LoadingSubmit} />
        </Box>
    );
}
