import React from 'react';
import Hero from './Hero';
import Guide from './Guide';
import Details from './Details';
import { withLayoutHomePage } from '../../hoc/withLayoutHomePage';
import Donate from './Donate';

import HotCourse from './HotCourse';

function HomePage(props = {}) {
    return (
        <React.Fragment>
            <Hero />
            <Details />
            <Guide />
            <HotCourse />
            <Donate />
        </React.Fragment>
    );
}
export default withLayoutHomePage(HomePage);
