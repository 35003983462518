import { Box, styled, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import heroImg from '../../media/banner.png';
import CustomButton from '../common/CustomButton';

const Hero = () => {
    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(5),
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const Image = styled('img')({
        width: '633px',
        marginBottom: '2rem',
        marginTop: '100px',
        '@media screen and (max-width:500px)': {
            width: '350px',
            marginTop: '30px',
        },
    });

    return (
        <Box sx={{ backgroundColor: '#E6F0FF', minHeight: '80vh', paddingBottom: '40px', padding: '1px' }}>
            <Container>
                {/* <Navbar /> */}
                <CustomBox>
                    <Box sx={{ flex: '1' }}>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: '18px',
                                color: '#687690',
                                fontWeight: '500',
                                mt: 10,
                                mb: 4,
                            }}
                        >
                            Chào mừng đến với Lập trình FullStack
                        </Typography>
                        <TypeAnimation
                            sequence={[
                                "Discover a place where you'll love to learn.",
                                1000,
                                'BEYOND ... YOUR CODING SKILLS ...',
                                1000,
                                'Learn practical knowledge',
                                1000,
                            ]}
                            speed={10}
                            className="banner__title"
                            repeat={Infinity}
                            deletionSpeed={90}
                        />
                        <Typography variant="body2" sx={{ fontSize: '18px', color: '#5A6473', my: 4 }}>
                            Mình tên là Thành, người sáng lập kênh Youtube "Lập trình FullStack", cựu sinh viên CNTT trường Học viện công
                            nghệ Bưu chính viễn thông, và đang là một Developer FullStack tại công ty nào đó :D
                        </Typography>
                        <Link target={'_blank'} to={'https://www.youtube.com/channel/UCnz2rsSOw4JDYilb8aobqHA'}>
                            <CustomButton backgroundColor="#0F1B4C" color="#fff" buttonText="Xem kênh youtube" heroBtn={true} />
                        </Link>
                    </Box>

                    <Box sx={{ flex: '1.25' }}>
                        <Image className="banner-hero__img" src={heroImg} alt="heroImg" />
                    </Box>
                </CustomBox>
            </Container>
        </Box>
    );
};

export default Hero;
