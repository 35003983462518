import  Box  from "@mui/material/Box";
import React from "react";
import MomoImg from '../../media/momo.jpg'
import VietcombankImg from '../../media/vietcombank.jpg'
import BgDonateImg from '../../media/bgDonate.jpg'
import { styled } from '@mui/system';

const DonateWrapper = styled('Box')({
    backgroundColor:"#fff",
    borderRadius:"10px",
    padding:"20px",
    display:'flex',
    gap: '20px',
    flexWrap:'wrap'
});
const ImageMomo = styled('img')({
    width:"300px",
    borderRadius:"10px",
    "@media screen and (max-width:500px)":{
        width:'100%'
    }
});
const ImageVCB = styled('img')({
    width:"300px",
    borderRadius:"10px",
    border: "1px solid #ccc",
    "@media screen and (max-width:500px)":{
        width:'100%'
    }
});
export default function DonateClient(props = {}){

 
    return(
        <Box style={{backgroundImage: `url(${BgDonateImg})`,}}
        className='backgroundWrapper'
        >
            <h1 style={{textTransform:'uppercase',textAlign:'center',color:"#fff"}}>Cho đi để nhận lại</h1>
            <DonateWrapper >
               <ImageMomo  src={MomoImg} alt="momo img"></ImageMomo>
               <ImageVCB src={VietcombankImg} alt="vcb img"></ImageVCB>
            </DonateWrapper>
        </Box>
    )
}