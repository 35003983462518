import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Header from '../../Components/common/HeaderList';
import SubmitLoading from '../../Components/common/SubmitLoading';
import { useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { FORMAT } from '../../utils/constant';
import { tokens } from '../../theme';
import { useFetchAllCourse } from '../../hooks/fetchAllCourse';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { createOrderService } from '../../services/orderService';
import { toast } from 'react-toastify';
export default function AddOrder() {
    const [LoadingSubmit, setLoadingSubmit] = useState(false);
    const [inputValues, setInputValues] = useState({
        customerName: '',
        isOpen: false,
        date: new Date(),
        note: '',
        productList: [{ courseId: '', realPrice: 0 }],
    });

    const { productList } = inputValues;

    const { dataCourse } = useFetchAllCourse('', '', '', '');
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleOnChangeOrderItem = (name, value, index) => {
        const productListTemp = [...productList];
        productListTemp[index] = { ...productListTemp[index], [name]: value };

        setInputValues((prev) => ({
            ...prev,
            productList: productListTemp,
        }));
    };

    const handleDeleteCourse = (index) => {
        const productListTemp = [...productList];
        productListTemp.splice(index, 1);
        setInputValues((prev) => ({
            ...prev,
            productList: productListTemp,
        }));
    };

    const handleSaveOrder = async () => {
        setLoadingSubmit(true);
        const res = await createOrderService(inputValues);
        if (res) {
            toast.success('Tạo đơn hàng thành công');
            setInputValues({
                customerName: '',
                isOpen: false,
                date: new Date(),
                note: '',
                productList: [''],
            });
        }
        setLoadingSubmit(false);
    };

    return (
        <Box m="20px">
            <Header title="TẠO ĐƠN HÀNG" subtitle="Tạo đơn hàng mới" />

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                    '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
                }}
            >
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Tên khách hàng"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.firstName}
                    name="customerName"
                    color="secondary"
                    sx={{ gridColumn: 'span 2' }}
                />
                <DesktopDatePicker
                    label="Date"
                    inputFormat={FORMAT.FORMART_DATE}
                    value={inputValues.date}
                    name="date"
                    onChange={(value) => setInputValues({ ...inputValues, date: value['$d'] })}
                    renderInput={(params) => <TextField color="secondary" fullWidth variant="filled" {...params} />}
                />
                <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Ghi chú"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.note}
                    name="note"
                    color="secondary"
                    sx={{ gridColumn: 'span 4' }}
                    multiline
                    rows={2}
                />
                <Box sx={{ gridColumn: 'span 4' }}>
                    <Typography variant="h5" fontWeight={500} mb={2} color={colors.grey[100]}>
                        Chi tiết đơn hàng
                    </Typography>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                            setInputValues((prev) => ({
                                ...prev,
                                productList: [...prev.productList, { courseId: null, realPrice: 0 }],
                            }))
                        }
                    >
                        <AddCircleOutlineIcon sx={{ marginRight: 1 }} />
                        Thêm
                    </Button>
                </Box>

                {productList?.length > 0 &&
                    productList.map((item, index) => {
                        return (
                            <Stack sx={{ gridColumn: 'span 4' }} direction={'row'} gap={'12px'} alignItems={'center'}>
                                <Stack direction={'row'} gap={'12px'} sx={{ flex: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>Chọn khóa học</InputLabel>
                                        <Select
                                            label={'Chọn khóa học'}
                                            value={item.courseId}
                                            onChange={(e) => handleOnChangeOrderItem('courseId', e.target.value, index)}
                                        >
                                            {dataCourse?.length > 0 &&
                                                dataCourse.map((course) => {
                                                    return <MenuItem value={course.id}>{course.title}</MenuItem>;
                                                })}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Giá tiền"
                                        value={item.realPrice}
                                        name="note"
                                        color="secondary"
                                        onChange={(e) => handleOnChangeOrderItem('realPrice', e.target.value, index)}
                                    />
                                </Stack>

                                <Box sx={{ flex: 1 }}>
                                    <Button color="redAccent" variant="contained" onClick={(e) => handleDeleteCourse(index)}>
                                        <DeleteSweepIcon sx={{ marginRight: 1 }} />
                                        Xóa
                                    </Button>
                                </Box>
                            </Stack>
                        );
                    })}

                <Box display={'flex'} gap="10px" alignItems={'center'}>
                    <Button onClick={() => handleSaveOrder()} type="submit" color="info" variant="contained">
                        Lưu
                    </Button>
                </Box>
            </Box>

            <SubmitLoading loading={LoadingSubmit} />
        </Box>
    );
}
