import { useEffect, useState } from 'react';
import { Box, useTheme, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../Components/common/HeaderList';
import { Link } from 'react-router-dom';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { USER_REFERENCE } from '../../utils/constant';
import { getAllCourseService } from '../../services/courseService';
import EditIcon from '@mui/icons-material/Edit';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { formatNumber } from '../../utils/CommonUtils';

export default function Course(props = {}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataCourse, setdataCourse] = useState([]);

    const [arrCourseId, setarrCourseId] = useState([]);
    const [ColumnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        loadDataCourse();
    }, []);
    const loadDataCourse = async () => {
        setisLoading(true);
        let res = await getAllCourseService({
            limit: '',
            offset: '',
            keyword: '',
        });
        if (res && res.errCode === 0) {
            res.data = res.data.map((item) => {
                return {
                    ...item,
                    levelName: item.levelData.value,
                };
            });
            setdataCourse(res.data);
            setisLoading(false);
        }
    };

    const handleOnchangeColumns = (newModel) => {
        let userReference = JSON.parse(localStorage.getItem(USER_REFERENCE.USER_REFERENCE));
        localStorage.setItem(USER_REFERENCE.USER_REFERENCE, JSON.stringify({ ...userReference, tableCourse: newModel }));
        setColumnVisibilityModel(newModel);
    };
    const columns = [
        { field: 'id', headerName: 'ID' },
        {
            field: 'title',
            headerName: 'Tiêu đề',
            flex: 1,
            cellClassName: 'name-column--cell',
        },
        {
            field: 'levelName',
            headerName: 'Cấp độ',
            flex: 1,
        },
        {
            field: 'price',
            headerName: 'Giá tiền',
            flex: 1,
            renderCell: ({ row: { price } }) => {
                return (
                    <Typography color={colors.redAccent[500]} sx={{ ml: '5px' }}>
                        {formatNumber(price)}
                    </Typography>
                );
            },
        },
        {
            headerName: 'Action',
            flex: 1,
            renderCell: ({ row: { id } }) => {
                return (
                    <Link to={`/admin/course/addCourse/${id}`} style={{ textDecoration: 'none', color: colors.primary[500] }}>
                        <Button type="submit" color="redAccent" variant="contained">
                            <EditIcon sx={{ marginRight: 1 }} />
                            Edit
                        </Button>
                    </Link>
                );
            },
        },
    ];

    return (
        <Box m="20px">
            <Header title="KHÓA HỌC" subtitle="Quản lý khóa học" />
            <Box display="flex" justifyContent="end">
                <Link to={'/admin/course/addCourse'} style={{ textDecoration: 'none' }}>
                    <Button type="submit" color="secondary" variant="contained">
                        <OndemandVideoIcon sx={{ marginRight: 1 }} />
                        Thêm khóa học
                    </Button>
                </Link>
                <Button type="submit" color="redAccent" variant="contained" sx={{ marginLeft: 1 }}>
                    <DeleteSweepIcon sx={{ marginRight: 1 }} />
                    Xóa
                </Button>
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={{
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                    '& .name-column--cell': {
                        color: colors.greenAccent[300],
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        backgroundColor: colors.primary[400],
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                        backgroundColor: colors.blueAccent[700],
                    },
                    '& .MuiCheckbox-root': {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    '& .MuiCircularProgress-root': {
                        color: colors.greenAccent[400],
                    },
                }}
            >
                <DataGrid
                    onSelectionModelChange={(arrCourseId) => {
                        setarrCourseId(arrCourseId);
                    }}
                    selectionModel={arrCourseId}
                    checkboxSelection
                    loading={isLoading}
                    rows={dataCourse}
                    columns={columns}
                    columnVisibilityModel={ColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => handleOnchangeColumns(newModel)}
                />
            </Box>
        </Box>
    );
}
