import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import IntlProviderWrapper from './hoc/IntlProviderWrapper';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <BrowserRouter>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <IntlProviderWrapper>
                            <App />
                        </IntlProviderWrapper>
                    </PersistGate>
                </Provider>
            </BrowserRouter>
        </StyledEngineProvider>
        ,
    </React.StrictMode>,
    document.getElementById('root'),
);
