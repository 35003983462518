import { Box } from '@mui/material';
import { useTheme, Typography, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import { tokens } from '../../theme';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { handleLoginService,getDetailUserByEmail,createNewUser } from '../../services/userService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TYPE_LOGIN } from '../../utils/constant';
import { HandleLoginStart } from '../../redux/action/UserAction';
import { useDispatch } from 'react-redux';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import { authentication } from "../../utils/firebase";
import SubmitLoading from '../../Components/common/SubmitLoading';
export default function LoginPage() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        email: '',
        password: '',
    });
    const [LoadingSubmit, setLoadingSubmit] = useState(false);
    const dispatch = useDispatch();
    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    const handleLogin = async (email,password) => {
        localStorage.setItem(TYPE_LOGIN.TYPE_LOGIN, TYPE_LOGIN.TRADITIONAL);

        // ===================== LOGIN TRUYEN THONG//////////////////
        setLoadingSubmit(true)
        let res = await handleLoginService({
            email: email,
            password: password,
        });
        if (res && res.errCode === 0) {
            setLoadingSubmit(false)
            dispatch(HandleLoginStart(res.user));
            if (res.user.roleId === TYPE_LOGIN.ROLE_ADMIN) {
                navigate('/admin');
            } else {
                navigate('/');
            }
        } else {
            setLoadingSubmit(false)
            toast.error(res.errMessage);
        }
    };
    const LoginWithSocial = async(re) =>{
        setLoadingSubmit(true)
        let user = await getDetailUserByEmail(re.user.email)
        if(user && user.errCode ===0){
            handleLogin(re.user.email,re.user.uid)
        }else{
            let res = await createNewUser({
                email: re.user.email,
                lastName: re.user.displayName,
                phonenumber: re.user.phoneNumber,
                image: re.user.photoURL,
                roleId: "R2",
                password: re.user.uid,
                isOauth:true
            })
            if (res && res.errCode === 0) {
                toast.success("Tạo tài khoản thành công")
                handleLogin(re.user.email,re.user.uid)
            } else {
                toast.error(res.errMessage)
            }
        }
    }
    let signInwithGoogle = async () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(authentication, provider)
            .then(async (re) => {
                LoginWithSocial(re)
            })
            .catch((err) => {
                console.log(err.message)
            })
    }
    let signInwithFacebook = () => {
        const provider = new FacebookAuthProvider()
        signInWithPopup(authentication, provider)
            .then((re) => {
                LoginWithSocial(re)
            })
            .catch((err) => {
                console.log(err.message)
            })
    }
    return (
        <Box display={'flex'} margin="0 auto" justifyContent="center" width={'100%'} alignItems={'center'} padding="40px 0px 100px 0px">
            <Box
                sx={{ backgroundColor: colors.primary[400], padding: '40px', borderRadius: '5px' }}
                display="flex"
                flexDirection={'column'}
                justifyContent="center"
                alignItems={'center'}
                gap="20px"
            >
                <Typography variant="h3" color={colors.greenAccent[500]}>
                    Welcome to login !
                </Typography>
                <Typography variant="h5" color={colors.redAccent[500]}>
                    Sign in to your account to continue
                </Typography>
                <Box sx={{ backgroundColor: colors.primary[600] }} display="flex" gap={'10px'} padding="15px" borderRadius={'5px'}>
                    <InfoOutlinedIcon sx={{ color: colors.blueAccent[300] }} />
                    <Typography variant="h5" color={colors.blueAccent[300]}>
                    Đăng ký và đăng nhập bằng tài khoản google cho nhanh nhé các bạn !
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    label="Email"
                    name="email"
                    color="secondary"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.firstName}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    type="password"
                    label="Password"
                    name="password"
                    color="secondary"
                    onChange={(e) => handleOnChange(e)}
                    value={inputValues.firstName}
                />
                <FormControlLabel sx={{ width: '100%' }} control={<Checkbox color="secondary" />} label="Remember me" />
                <Button
                    onClick={() => handleLogin(inputValues.email,inputValues.password)}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{ fontWeight: 700, fontSize: '14px' }}
                >
                    Sign In
                </Button>
                <FacebookLoginButton text="Đăng nhập với Facebook" iconSize="25px" style={{ height: "40px",width:'100%',margin:0,display:'flex',justifyContent:'center', fontSize: "16px"}} onClick={() => signInwithFacebook()} />
                <GoogleLoginButton text="Đăng nhập với Google" iconSize="25px" style={{  height: "40px",width:'100%',margin:0,display:'flex',justifyContent:'center',fontSize: "16px" }} onClick={() => signInwithGoogle()} />
            </Box>
            <SubmitLoading loading={LoadingSubmit} />
        </Box>
    );
}
